import * as React from 'react'
import Container from '../Container'
import * as style from './hero.module.scss'
import { StaticImage } from 'gatsby-plugin-image'

const Hero: React.FC = () => {
  return (
    <div id='homepage-Hero' className={style.hero}>
      <div className={style.wavesContainer}>
        <StaticImage className={style.waves} src='../../images/waves-bg.png' alt='waves background' />
      </div>
      <Container>
        <div className={style.content}>
          <StaticImage src='./top.png' alt='NEUROLOGS' />
          <StaticImage src='./text.png' alt='AI FOR BRAIN HEALTH' />
          <p>
            Offering innovative solutions for detecting, diagnosing and monitoring neurodegenerative and mental
            disorders.
          </p>
        </div>
      </Container>
    </div>
  )
}

export default Hero
