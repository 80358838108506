import * as React from 'react'
import type { HeadFC } from 'gatsby'
import Hero from '../components/Hero'
import Mission from '../components/Mission'
import Application from '../components/Application'
import Partner from '../components/Partner'
import Contact from '../components/Contact'
import MainLayout from '../Layouts/MainLayout'
import { SEO } from '../components/seo'

const IndexPage: React.FC = () => {
  return (
    <MainLayout>
      <main>
        <Hero />
        <Mission />
        <Application />
        <Partner />
        <Contact />
      </main>
    </MainLayout>
  )
}

export default IndexPage

export const Head: HeadFC = () => {
  return <SEO title='Home page' />
}
