import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import Container from '../Container'
import * as style from './app.module.scss'

const Application: React.FC = () => {
  return (
    <section id='homepage-Application' className={style.appContainer}>
      <Container>
        <div className={style.container}>
          <div className={style.imageContainer}>
            <StaticImage src='./sophie.png' alt='Sophie' />
          </div>
          <div className={style.wrapper}>
            <StaticImage className={style.logo} src='./neurologos.png' alt='neurologos' />
            <h2>YOUR VOICE COULD SAVE LIVES. </h2>
            <p>
              The human voice is a powerful tool that can be used for the early detection of some neurodegenerative
              diseases and mental disorders.
            </p>
            <p>Meet Sophie for a short cognitive test and give your voice to help medical research.</p>
            <div className={style.link}>
              <a href='//app.neurologs.org' rel='noreferrer' target='_blank'>
                Start the test
              </a>
            </div>
          </div>
          <div className={style.imageContainer}>
            <StaticImage src='./henry.png' alt='Henry' />
          </div>
        </div>
      </Container>
    </section>
  )
}

export default Application
