// extracted by mini-css-extract-plugin
export var active = "hero-module--active--qEwzZ";
export var container = "hero-module--container--qgHPQ";
export var content = "hero-module--content--qjotN";
export var hero = "hero-module--hero--LTvGl";
export var isTop = "hero-module--isTop--m9ALO";
export var logoContainer = "hero-module--logoContainer--8FkBw";
export var navbar = "hero-module--navbar--yPOqT";
export var navigation = "hero-module--navigation--DezTI";
export var waves = "hero-module--waves--FSoYG";
export var wavesContainer = "hero-module--wavesContainer--RdAbn";