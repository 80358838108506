import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import Container from '../Container'
import * as style from './partner.module.scss'
const Partner: React.FC = () => {
  return (
    <section id='homepage-Partner'>
      <Container>
        <div className={style.text}>
          <h2>Partner with us</h2>
          <p>
            We are looking for specialized partners to help us develop our Platform and implement our solutions :
            healthcare facilities, academic research institutions, pharmaceutical companies, startups, physicians,
            neuroscientists, data scientists.
          </p>
          <p>If you’re interested in working with Neurologs or joining our team, please contact us.</p>
        </div>
        <div className={style.logoPartner}>
          <div className={style.partner}>
            <StaticImage height={100} src='./logos/Connect_innov.png' alt='Connect innov' />
          </div>
          <div className={style.partner}>
            <StaticImage height={100} src='./logos/Smart Capital.png' alt='Smart Capital' />
          </div>
          <div className={style.partner}>
            <StaticImage height={100} src='./logos/Impact Partner.png' alt='Impact Partner' />
          </div>
          <div className={style.partner}>
            <StaticImage height={100} src='./logos/Expertise France.png' alt='Expertise France' />
          </div>
          <div className={style.partner}>
            <StaticImage height={100} src='./logos/Eurobiomed.png' alt='Eurobiomed' />
          </div>
        </div>
      </Container>
    </section>
  )
}

export default Partner
